import React from 'react'

import WordExample from './WordExample'
import WordHeader from './WordHeader'
import WordTraduction from './WordTraduction'
import SocialShare from './SocialShare'

import {parameters, parameters1} from '../../helpers/voiceCallback.js'

import SpeakerButton from './SpeakerButton'

import styled from 'styled-components'

import useIsMobile from '../../hooks/useIsMobile'

const BoxWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 80px;
  padding: 0 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    margin: 0;
    gap: 0;
    width: 100%;
    padding: 0;
  }
`

const BoxContainer = styled.div`
  position: relative;
  width: 100%;
  height: 400px;
  @media screen and (max-width: 767px) {
    height: auto;
    min-height: auto;
    width: 100%;
  }
`

const FrontDiv = styled.div`
  position: absolute;
  flex-direction: column;
  display: flex;
  border-radius: 8px;
  border: 3px solid black;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 2;
  transform: translateZ(50px);
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 767px) {
    position: relative;
    transform: none;
    border-radius: 0;
    border-left: none;
    border-right: none;
    border-bottom: none;
  }
`

const BackDiv = styled.div`
  position: absolute;
  border-radius: 8px;
  border: 3px solid black;
  top: 10px;
  left: 10px;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: #57d0ff;

  @media screen and (max-width: 767px) {
    display: none;
  }
`

const GridContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: fit-content;
  width: 100%;
  padding: 30px;

  @media screen and (max-width: 767px) {
    padding: 20px 15px;
  }
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 25px;
  align-items: flex-start;

  &:last-child {
    margin-bottom: 0;
  }

  @media screen and (max-width: 767px) {
    flex-direction: column;
    margin-bottom: 20px;
    gap: 8px;
  }
`

const Cell = styled.div`
  flex: 1;
  padding: 10px;
  display: flex;
  align-items: center;
  font-size: 1.25rem;
  line-height: 1.4;

  @media screen and (max-width: 767px) {
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding: 8px 5px;
    font-size: 1.1rem;
    width: 100%;
  }
`

const Button = styled.h3`
  border: 3px solid black;
  border-radius: 8px;
  width: fit-content;
  padding: 15px 25px;
  box-shadow: rgb(0, 0, 0) 3px 4px 0px;
  margin: 0;
  cursor: pointer;
  transition: transform 0.2s;

  &:hover {
    transform: translateY(-2px);
  }

  @media screen and (max-width: 767px) {
    padding: 12px 20px;
    font-size: 1.1rem;
  }
`

const WordBoxV2 = ({node}) => {
  const isMobile = useIsMobile()

  return (
    <>
      <BoxWrapper>
        <BoxContainer>
          <FrontDiv>
            <GridContainer>
              <Row>
                <Cell
                  style={{textDecoration: isMobile && 'underline'}}
                  className="is-size-5 has-text-weight-medium"
                >
                  La palabra del dia es:
                </Cell>
                <Cell style={{backgroundColor: ''}}>
                  <SpeakerButton
                    id="buttonMot"
                    sentenceToSpeak={node.mot}
                    parameters={parameters}
                  >
                    {node.word}
                  </SpeakerButton>
                </Cell>
              </Row>
              <Row>
                <Cell
                  style={{textDecoration: isMobile && 'underline'}}
                  className="is-size-5 has-text-weight-medium"
                >
                  Traducción:
                </Cell>
                <Cell style={{backgroundColor: ''}}> {node.trad}</Cell>
              </Row>
              <Row>
                <Cell
                  style={{textDecoration: isMobile && 'underline'}}
                  className="is-size-5 has-text-weight-medium is-underlined"
                >
                  Ejemplo Francés:
                </Cell>
                <Cell style={{backgroundColor: ''}}>
                  <SpeakerButton
                    id="buttonEjemplo"
                    sentenceToSpeak={node.ejemplo}
                    parameters={parameters1}
                  >
                    {node.ejemplo}
                  </SpeakerButton>
                </Cell>
              </Row>
              <Row>
                <Cell
                  style={{textDecoration: isMobile && 'underline'}}
                  className="is-size-5 has-text-weight-medium"
                >
                  En Español:
                </Cell>
                <Cell className="is-size-5"> {node.tradEjemplo}</Cell>
              </Row>
            </GridContainer>
          </FrontDiv>
          <BackDiv></BackDiv>
        </BoxContainer>
      </BoxWrapper>
    </>
    // <div className="containerMot  ">
    //   <div className="box fadeIn" style={{border: '3px solid black'}}>
    //     <WordHeader word={node.mot} />
    //     <div className="container">
    //       <WordTraduction translation={node.trad} />
    //       <hr style={{marginTop: '0'}} />
    //       <WordExample
    //         example={node.ejemplo}
    //         exampleTranslation={node.tradEjemplo}
    //       />
    //     </div>
    //   </div>
    //   {/* <SocialShare word={node.mot} /> */}
    // </div>
  )
}

export default WordBoxV2
